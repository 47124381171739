// i18next-extract-mark-ns-start payment-methods
import {Background} from 'components/Background';
import {ContactSalesButton} from 'components/ContactSalesButton';
import {Content} from 'components/Content';
import {PaymentMethodLogo, PaymentMethodsContainer} from 'components/PaymentMethods';
import {Section, SectionActions, SectionHeader} from 'components/Section';
import {SEO} from 'components/SEO';
import {SignUpButton} from 'components/SignUpButton';
import {PageProps, graphql} from 'gatsby';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';
import React from 'react';
import styled from 'styled-components';
import {IndexBackground} from 'components/landings/IndexBackground';

// logos
import bizum_logo from 'images/bizum_payment_gateway.svg';
import click_to_pay_logo from 'images/click_to_pay.svg';
import multibanco_logo from 'images/multibanco_logo.svg';
import payment_methods from 'images/payment-methods.svg';
import paypal_logo from 'images/paypal_payment_gateway.svg';
import dinners_logo from '@wp-pay/logos/dist/cards/diners-club/card-diners-club-logo-_x80.svg';
import jcb_logo from '@wp-pay/logos/dist/cards/jcb/card-jcb-logo-_x80.svg';
import discover_logo from '@wp-pay/logos/dist/cards/discover/card-discover-logo-_x80.svg';
import applepay_logo from '@wp-pay/logos/dist/methods/apple-pay/method-apple-pay-640x360.svg';
import googlepay_logo from '@wp-pay/logos/dist/methods/google-pay/method-google-pay-640x360.svg';
import mastercard_logo from '@wp-pay/logos/dist/methods/mastercard/method-mastercard-640x360.svg';
import sepa_logo from 'images/sepa-logo.svg';
import visa_logo from '@wp-pay/logos/dist/methods/visa/method-visa-640x360.svg';
import bancontact_logo from '@wp-pay/logos/dist/methods/bancontact/method-bancontact-640x360.svg';
import monei_pay_form_en from 'images/monei_pay_form_en.png';
import monei_pay_form_es from 'images/monei_pay_form_es.png';
import mbWay_logo from 'images/mbway-logo.svg';
import {GradientBox} from 'components/landings/GradientBox';
import {IosDownloadLink} from 'components/DownloadOnAppleStore';
import {AndroidDownloadLink} from 'components/DownloadOnGooglePlay';
import {ScreenSizes} from '../../../types/responsive';
import {LanguageEnum} from '../../../locales/types';
import {IndexImage} from 'components/landings/IndexImage';
import {InternalPageLink} from 'components/links/Pages';
import {BlogLink} from 'components/links/Blog';
import Bold from 'components/Bold';
import {FaqsSection} from 'components/FaqsSection';
import {GoogleReviews, Place} from 'components/GoogleReviews';

type Props = {
  pageContext: {
    pagePath: string;
    title: string;
  };
};

const IndexSection = styled.div`
  position: relative;
`;

const IndexContent = styled.div`
  padding-top: 100px;
  padding-bottom: 90px;
  max-width: 515px;
`;

const PaymentsSection = styled(Section)`
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
`;

const PaymentsBackground = styled(Background)`
  padding-top: 150px;
  padding-bottom: 120px;
  @media (max-width: 768px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
`;

const SectionDescription = styled.p`
  font-weight: 500;
  color: #fafafa;
  font-size: 16px;

  p {
    font-size: 1.25rem;
  }

  a {
    color: #ffffff;
    text-decoration: underline;
  }
`;

const CtaImage = styled.img`
  position: absolute;
  bottom: -6px;
  right: 98px;
  width: 19.5%;

  @media (max-width: ${ScreenSizes.lg}) {
    bottom: 0;
    width: 240px;
    right: 60px;
  }
  @media (max-width: ${ScreenSizes.md}) {
    display: none;
  }
`;

const EmptyPMCard = styled.div`
  width: 270px;
  @media (max-width: ${ScreenSizes.lg}) {
    display: none;
  }
`;

const ReviewsBackground = styled.div`
  position: relative;
  margin-top: 100px;

  &:before {
    display: block;
    content: '';
    z-index: -1;
    height: 70%;
    position: absolute;
    transform: skew(0, -9deg);
    left: 0;
    right: 0;
    bottom: 0;
    top: 20%;
    background: linear-gradient(96.34deg, #00e9d5 0%, #aab4e9 100%);
  }
`;

const Index: React.FC<PageProps> = (props) => {
  const {t, language} = useI18next();
  const isSpanish = [LanguageEnum.es, LanguageEnum.ca].includes(language as LanguageEnum);
  const monei_pay_form = isSpanish ? monei_pay_form_es : monei_pay_form_en;
  const title = t('Accept More Payment Methods');
  const {data} = props;
  const place: Place = {
    rating: data.googlePlacesPlace.rating,
    user_ratings_total: data.googlePlacesPlace.user_ratings_total,
    reviews: data.allGooglePlacesReview.nodes
  };
  const faqs = [
    {
      header: t('What is a payment method?'),
      text: t(
        'Ranging from credit and debit cards to prepaid cards to mobile wallets (and more), a payment method is a way customers pay for products or services online or in person.'
      ),
      content: (
        <Trans parent="p">
          Ranging from credit and debit cards to prepaid cards to mobile wallets (and more), a{' '}
          <BlogLink slug="types-of-payment-methods">payment method</BlogLink> is a way customers pay
          for products or services online or in person.
        </Trans>
      )
    },
    {
      header: t('What is a default payment method?'),
      text: t(
        'A default payment method is an option that automatically appears on your payment page or physical point of sale as the main method of payment.'
      ),
      content: (
        <Trans parent="p">
          A default payment method is an option that automatically appears on your payment page or
          physical point of sale as the main method of payment.
        </Trans>
      )
    },
    {
      header: t('What is an alternative payment method?'),
      text: t(
        'Alternative payment methods (APMs) are essentially any payment type that isn’t cash or a credit/debit card from a major network like Visa or Mastercard. For example, digital wallets, peer-to-peer payment apps, or wearables are all considered alternative payment methods.'
      ),
      content: (
        <Trans parent="p">
          <BlogLink slug="alternative-payment-methods">Alternative payment methods (APMs)</BlogLink>{' '}
          are essentially any payment type that isn’t cash or a credit/debit card from a major
          network like Visa or Mastercard. For example,{' '}
          <BlogLink slug="digital-wallet-ecommerce">digital wallets</BlogLink>, peer-to-peer payment
          apps, or wearables are all considered alternative payment methods.
        </Trans>
      )
    },
    {
      header: t('What is a local payment method?'),
      text: t(
        'Payment methods that are popular and/or only available in certain countries are considered local payment methods. For example, Bizum is a local payment method in Spain.'
      ),
      content: (
        <Trans parent="p">
          Payment methods that are popular and/or only available in certain countries are considered{' '}
          <BlogLink slug="local-payment-methods">local payment methods</BlogLink>. For example,{' '}
          <InternalPageLink slug="bizum-empresas">Bizum</InternalPageLink> is a local payment method
          in Spain.
        </Trans>
      )
    },
    {
      header: t('What are the most popular payment methods in Spain?'),
      text: t(
        'According to our data, the most popular payment methods in Spain include credit cards, Bizum, and PayPal. But Click to Pay is gaining popularity. Mobile purchases are also growing with the number of consumers making purchases via mobile devices nearly doubling the number of desktop purchases.'
      ),
      content: (
        <Trans parent="p">
          According to our data, the{' '}
          <BlogLink slug="black-friday-cyber-monday-payment-trends-2022">
            most popular payment methods in Spain
          </BlogLink>{' '}
          include credit cards, Bizum, and PayPal. But{' '}
          <BlogLink slug="click-to-pay-ecommerce">Click to Pay</BlogLink> is gaining popularity.
          Mobile purchases are also growing with the number of consumers making purchases via mobile
          devices nearly doubling the number of desktop purchases.
        </Trans>
      )
    },
    {
      header: t('How many payment methods should I accept?'),
      text: t(
        'The more payment methods you accept, the more customers you can reach. But you also need to consider the cost of accepting each payment method and the potential for fraud.'
      ),
      content: (
        <Trans parent="p">
          Whether you’re selling online or in person, you can never accept too many payment methods.
          Reduce abandoned cart rates and speed up customer queues by accepting a wide range of
          payment methods at your business.
        </Trans>
      )
    }
  ];

  const schemaFaqs = {
    '@type': 'FAQPage',
    mainEntity: faqs.map((faq) => ({
      '@type': 'Question',
      name: faq.header,
      acceptedAnswer: {
        '@type': 'Answer',
        text: faq.text
      }
    }))
  };

  const description = t(
    `Accept more payment methods online and in-person and do it all with a single integration. Manage your entire omnichannel payment stack with MONEI. Start here ››`
  );
  return (
    <>
      <IndexBackground sx={{left: {xl: '50%'}, height: {xl: '1000px'}, width: {xl: '2000px'}}}>
        <Content>
          <SEO title={title} description={description} schema={schemaFaqs} />
          <IndexSection>
            <IndexContent>
              <SectionHeader underline tagName="h1">
                <Trans>
                  Accept more <br />
                  payment methods
                </Trans>
              </SectionHeader>
              <Trans parent="p">
                Boost customer satisfaction and sales by{' '}
                <Bold>accepting more payment methods (online and off)</Bold>. Save time and money by
                managing your entire omnichannel payment stack — from cards to digital wallets to
                local payment methods like Bizum in Spain — <Bold>from a single platform</Bold>.
              </Trans>
              <Trans parent="p">
                <Bold>Get MONEI</Bold> to integrate with all major{' '}
                <Bold>e-commerce platforms or your custom-built website</Bold>. Add alternative
                payment methods to your checkout and take physical card, Google Pay, Apple Pay, and
                Bizum <Bold>payments from anywhere using your phone</Bold> — no website required.
              </Trans>
              <SectionActions align="left">
                <SignUpButton variant="light">
                  <Trans>Open an Account</Trans>
                </SignUpButton>
                <ContactSalesButton />
              </SectionActions>
            </IndexContent>
            <IndexImage
              left="50%"
              top="120px"
              width="700px"
              src={payment_methods}
              title={title}
              alt={title}
              className="hide-on-mobile"
            />
          </IndexSection>
        </Content>
      </IndexBackground>
      <PaymentsBackground>
        <Content>
          <PaymentsSection>
            <div>
              <SectionHeader tagName="h3">
                <Trans>Credit Card Payments</Trans>
              </SectionHeader>
              <PaymentMethodsContainer>
                <InternalPageLink slug="payment-methods/credit-cards">
                  <PaymentMethodLogo
                    src={visa_logo}
                    alt="Visa payment gateway"
                    title="Visa payment gateway"
                    width={150}
                  />
                </InternalPageLink>
                <InternalPageLink slug="payment-methods/credit-cards">
                  <PaymentMethodLogo
                    src={mastercard_logo}
                    alt="Mastercard payment gateway"
                    title="Mastercard payment gateway"
                    width={200}
                  />
                </InternalPageLink>
                <InternalPageLink slug="payment-methods/credit-cards">
                  <PaymentMethodLogo
                    src={jcb_logo}
                    alt="JCB payment gateway"
                    title="JCB payment gateway"
                    width={110}
                  />
                </InternalPageLink>
                <InternalPageLink slug="payment-methods/credit-cards">
                  <PaymentMethodLogo
                    src={dinners_logo}
                    alt="Dinners Club payment gateway"
                    title="Dinners Club payment gateway"
                    width={180}
                  />
                </InternalPageLink>
                <InternalPageLink slug="payment-methods/credit-cards">
                  <PaymentMethodLogo
                    src={discover_logo}
                    alt="Discover payment gateway"
                    title="Discover payment gateway"
                    width={180}
                  />
                </InternalPageLink>
                <InternalPageLink slug="payment-methods/bancontact">
                  <PaymentMethodLogo
                    src={bancontact_logo}
                    alt="Bancontact payment gateway"
                    title="Bancontact payment gateway"
                    width={130}
                  />
                </InternalPageLink>
              </PaymentMethodsContainer>
            </div>
          </PaymentsSection>
          <PaymentsSection>
            <div>
              <SectionHeader tagName="h3">
                <Trans>Direct Debit or Bank Transfer Payments</Trans>
              </SectionHeader>
              <PaymentMethodsContainer>
                <InternalPageLink slug="payment-methods/sepa-direct-debit">
                  <PaymentMethodLogo
                    src={sepa_logo}
                    alt="SEPA payment gateway"
                    title="SEPA payment gateway"
                    width={170}
                  />
                </InternalPageLink>
                <InternalPageLink slug="payment-methods/multibanco">
                  <PaymentMethodLogo
                    src={multibanco_logo}
                    alt="Multibanco payment gateway"
                    title="Multibanco payment gateway"
                    width={130}
                  />
                </InternalPageLink>
                <InternalPageLink slug="payment-methods/mb-way">
                  <PaymentMethodLogo
                    src={mbWay_logo}
                    alt="MB Way payment gateway"
                    title="MB Way payment gateway"
                    width={200}
                  />
                </InternalPageLink>
                <InternalPageLink slug="payment-methods/bizum">
                  <PaymentMethodLogo
                    src={bizum_logo}
                    alt="Bizum payment gateway"
                    title="Bizum payment gateway"
                    width={170}
                  />
                </InternalPageLink>
              </PaymentMethodsContainer>
            </div>
          </PaymentsSection>
          <PaymentsSection>
            <div>
              <SectionHeader tagName="h3">
                <Trans>E-wallet or Digital Wallet Payments</Trans>
              </SectionHeader>
              <PaymentMethodsContainer>
                <InternalPageLink slug="payment-methods/paypal">
                  <PaymentMethodLogo
                    src={paypal_logo}
                    alt="PayPal payment gateway"
                    title="PayPal payment gateway"
                    width={190}
                  />
                </InternalPageLink>
                <InternalPageLink slug="payment-methods/apple-pay">
                  <PaymentMethodLogo
                    src={applepay_logo}
                    alt="Apple Pay payment gateway"
                    title="Apple Pay payment gateway"
                    width={180}
                  />
                </InternalPageLink>
                <InternalPageLink slug="payment-methods/google-pay">
                  <PaymentMethodLogo
                    src={googlepay_logo}
                    alt="Google Pay payment gateway"
                    title="Google Pay payment gateway"
                    width={180}
                  />
                </InternalPageLink>
                <InternalPageLink slug="payment-methods/click-to-pay">
                  <PaymentMethodLogo
                    src={click_to_pay_logo}
                    alt="Click to Pay payment gateway"
                    title="Click to Pay payment gateway"
                    width={200}
                  />
                </InternalPageLink>
              </PaymentMethodsContainer>
            </div>
          </PaymentsSection>
        </Content>
      </PaymentsBackground>
      <ReviewsBackground>
        <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
          <SectionHeader underline sx={{textAlign: 'center'}}>
            <Trans>What MONEI merchants are saying</Trans>
          </SectionHeader>
        </div>
        <GoogleReviews place={place} />
      </ReviewsBackground>
      <Content>
        <Section centered column>
          <FaqsSection faqs={faqs} title={<Trans>Payment method FAQs</Trans>} />
        </Section>
      </Content>
      <Content>
        <GradientBox wrapperStyle={{overflow: 'visible'}}>
          <SectionHeader tagName="h3" underline style={{color: 'white'}}>
            <Trans>Power your omnichannel payments with MONEI</Trans>
          </SectionHeader>
          <SectionDescription>
            <Trans parent="p">
              Get started today (without obligation) and download your mobile payment app on one or
              many <IosDownloadLink>iOS</IosDownloadLink> or{' '}
              <AndroidDownloadLink>Android</AndroidDownloadLink> mobile devices.
            </Trans>
          </SectionDescription>
          <SectionActions>
            <SignUpButton variant="dark">
              <Trans>Open an Account</Trans>
            </SignUpButton>
          </SectionActions>
          <CtaImage src={monei_pay_form} />
        </GradientBox>
      </Content>
    </>
  );
};

export default Index;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {ns: {in: ["common", "payment-methods"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    googlePlacesPlace {
      user_ratings_total
      rating
    }
    allGooglePlacesReview(sort: {fields: rating, order: DESC}, filter: {rating: {gt: 2}}) {
      nodes {
        id
        rating
        text
        relative_time_description
        profile_photo_url
        author_name
      }
    }
  }
`;
